import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import { IIndicationShow, IIndicationsResponse } from '../../interfaces/indications/indications.interface'
import { CreateIndicationFormData } from '../../pages/indications/Indications'
import { UpdateIndicationFormData } from '../../pages/indications/components/ShowIndication'
import { tranformeValideStringDate } from '../../utils/Helper'
import api from '../Api'

export const storeIndications = async (data: CreateIndicationFormData) => {
    const payload = {
        company: data.company,
        contact: data.contact,
        email: data.email,
        phone: data.phone,
        id_group_indications: data.id_group_indications,
        id_user_indications: data.id_user_indications,
        indications_date: tranformeValideStringDate(data.indications_date),
        obervations: data.obervations
    }
    return await api.post(`indications/group/${data.id_group}`, { ...payload })
}

export const litIndications = async (id_group: number, params: string ) => {
    return await api.get<IResponseGeneric<IIndicationsResponse>>(`indications/group/${id_group}${params}`)
}

export const showIndication = async (id: number) => {
    return await api.get<IIndicationShow>(`indications/${id}`)
}

export const updateIndication = async (id: number, data: UpdateIndicationFormData) => {
    const payload = {
        company: data.company,
        contact: data.contact,
        email: data.email,
        phone: data.phone,
        id_group_indications: data.id_group_indications,
        id_user_indications: data.id_user_indications,
        indications_date: tranformeValideStringDate( data.indications_date),
        obervations: data.obervations
    }
    return await api.put(`indications/${id}`, { ...payload })
}