import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import { IResponsePaginatorShow } from '../../interfaces/rdn/rdn.interface'
import { IDeleteTrainingMeetingParams, IParamsShowTraining, IStoreTrainingMeeting, IStoreTrainingMeetingParams, IStoreTrainingMeetingPresenceParams, ITrainingMeetingListParams, ITrainingMeetingListResponse, ITrainingMeetingPresenceParams, ITrainingMeetingPresenceResponse } from '../../interfaces/trainingMeeting/trainingMeeting.interface'
import api from '../Api'

export const storeTrainingMeeting = async (payload: IStoreTrainingMeeting) => {
    return await api.post(`/training-meeting/group/${payload.id_group}`, { ...payload })
}

export const getTrainingMeetingList = async ({ id_group, params }: ITrainingMeetingListParams) => {
    return await api.get<IResponseGeneric<ITrainingMeetingListResponse>>(`/training-meeting/group/${id_group}${params}`)
}

export const showTrainingMeetingPresence = async (params: ITrainingMeetingPresenceParams) => {
    return await api.get<ITrainingMeetingPresenceResponse>(`/training-meeting/group/${params.id_group}/training_meeting/${params.id_training_meeting}`) 
}

export const storePresence = async (params: IStoreTrainingMeetingPresenceParams) => {
    return await api.post(`/training-meeting/id_training_meeting/${params.id_training_meeting}`, { presence: params.presence })
}

export const storeTrainingMeetingV2 = async ({ id_group, id_user, meeting_date, guests }: IStoreTrainingMeetingParams) => {
    return await api.post(`/training-meeting/group/${id_group}/user/${id_user}`, { meeting_date, guests })
}

export const showTrainingMeeting = async ({ id_group, page, name }: IParamsShowTraining) => {
    return await api.get<IResponseGeneric<IResponsePaginatorShow>>(`/training-meeting/group/${id_group}`, { params: {page, name }})
}

export const deleteTrainingMeeting = async ({ id_trainingMeeting, id_reason, id_user, id_group }:IDeleteTrainingMeetingParams) => {
    return await api.delete(`/training-meeting/group/${id_group}/training-meeting/${id_trainingMeeting}/user/${id_user}/reason/${id_reason}` )
}

export const verificationTrainingMeeting = async ({ id_group, id_user, meeting_date, guests  }: IStoreTrainingMeetingParams) => {
    return await api.post<{rdnExist: boolean}>(`/training-meeting/verification`, { id_group, id_user, meeting_date, guests })
}